import { useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  height: 100vh;
  width: 100vw;
  z-index: 1;
  h1 {
    background-color: #fff;
    color: #000;
  }
`;

export const Overlay = ({ Component, isVisible, closeOverlay, extraProps }) => {
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isVisible]);

  return (
    isVisible && (
      <Container>
        <Component {...extraProps} closeOverlay={closeOverlay} />
      </Container>
    )
  );
};
