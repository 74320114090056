import styled from "styled-components";

import blurbg2 from "../assets/svg/blurbg2.svg";
import { motion } from "framer-motion";
import aboutImage from "../assets/png/about-image.png";
import { DESKTOP_MIN_WIDTH, LARGE_SCREEN_MIN_WIDTH, VARIANTS } from "../utils/constants";
import AnimateOnScroll from "../components/AnimateOnScroll";

const Container = styled.div`
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  background-color: #f1f9f1;

  img.blur-bg {
    display: block;
    height: 280px;
    width: 100%;
    object-fit: cover;
    background-color: white;
  }

  div.content-about {
    padding: 0 5%;
    position: relative;

    h1 {
      display: block;
      text-align: center;
      margin-top: -20rem;
      margin-bottom: 5rem;
      font-weight: 600;
      font-size: 4rem;
      line-height: 4.8rem;
      letter-spacing: -0.06em;
      color: #1c344f;
    }

    img {
      display: block;
      width: 100%;
      object-fit: contain;
      margin-bottom: 5rem;
    }

    div.p-container {
      padding: 0 5%;
      padding-bottom: 5rem;

      p {
        font-size: 2.4rem;
        line-height: 3.2rem;
        color: #1c344f;

        span {
          color: #55b653;
        }
      }
    }
  }

  @media screen and (min-width: ${LARGE_SCREEN_MIN_WIDTH}) {
    div.content-about {
      padding: 0 15%;
    }
  }

  @media screen and (max-width: ${DESKTOP_MIN_WIDTH}) {
    div.content-about {
      padding: 0 2rem;

      h1 {
        font-size: 3.2rem;
        line-height: 3.9rem;
        text-align: start;
      }

      div.p-container {
        padding: 0;
        padding-bottom: 3rem;

        p {
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
      }
    }
  }
`;

const easeing = [0.6, -0.05, 0.01, 0.99];

const AboutPage = () => {
  return (
    <Container>
      <img src={blurbg2} alt="" className="blur-bg" />

      <motion.div 
        className="content-about"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: easeing }}
      >
        <motion.h1
          initial={{ x: -200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          About Recyclo
        </motion.h1>

        <motion.div
          initial={{ y: 200, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <img src={aboutImage} alt="" />
        </motion.div>
        
        <AnimateOnScroll variants={VARIANTS.scaleIn}>
          <div className="p-container">
            <p>
              We are a group of young people willing to invest our time and energy
              in a transparent, non-profit organization to create a positive
              change for our future; combating specific problems such as garbage.
              <br />
              <br />
              We believe that we can guide the way to recycle in a much more
              intelligent way, generating zero waste.
              <br />
              <br />
              Our solution can be achieved with the help of tech. Basically we
              want to imitate nature. Being responsible consumers, the same pack
              will be used and rejected until its complete destruction. We also
              accept volunteers in this noble cause who can provide from ideas to
              all kinds of work needed to continue fighting a problem that affects
              us all.
              <br /> <br />
              <span>You too can be a part.</span>
            </p>
          </div>
        </AnimateOnScroll>
      </motion.div>
    </Container>
  );
};

export default AboutPage;
