import styled from "styled-components";
import PrivacyPolicyToc from "../components/privacy-toc.component";
import { COLORS } from "../utils/colors";
import { DESKTOP_MIN_WIDTH, VARIANTS } from "../utils/constants";
import { motion } from "framer-motion";
import AnimateOnScroll from "../components/AnimateOnScroll";
import { useMemo, useState } from "react";
import SectionInViewMonitor from "../components/SectionInViewMonitor";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${COLORS.grey_main};
    padding: 4rem 0;

    h1 {
      display: block;
      font-style: normal;
      font-weight: 600;
      font-size: 8.6rem;
      line-height: 10.6rem;
      letter-spacing: -0.04em;
    }

    span {
      display: block;
      font-weight: 400;
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  div.content {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    position: sticky;
    top: 30px;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      line-height: 3.2rem;
      text-align: justify;
      color: ${COLORS.grey_600};
    }

    div.left {
      padding-left: 5rem;
      width: 330px;
      padding-right: 1rem;
      box-sizing: content-box;
      position: sticky;
      top: 90px;
      height: calc(100vh - 90px);
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      ul {
        list-style: none;
        
        li {
          display: block;
          margin-bottom: 2rem;
        }

        li > a {    
          display: inline-block;        
          font-style: normal;
          font-weight: 500;
          font-size: 2.4rem;
          line-height: 2.9rem;
          text-decoration: none;
          letter-spacing: -0.04em;
          color: ${COLORS.grey_400};
          
          cursor: pointer;
          transition: 0.3s;
          text-transform: capitalize;

          &.selected {
            margin-left: 1rem;
            color: ${COLORS.primary};
          }

          &:hover {
            color: ${COLORS.primary};
            margin-left: 1.6rem;
          }
        }

      }

    }

    div.right {
      flex: 1;
      padding-right: 5rem;

      section {
        margin: 4rem 0;

        h3.title {
          display: block;
          font-weight: 600;
          font-size: 2rem;
          line-height: 3.2rem;
          text-align: justify;
          color: color: ${COLORS.grey_main};
          margin-bottom: 2rem;
          text-transform: uppercase;
          scroll-margin-top: 90px;
        }
      }
    } 
  }

  @media screen and (max-width: ${DESKTOP_MIN_WIDTH}) {
    height: auto;
    overflow-y: hidden;

    header {
      padding: 2rem 0;

      h1 {
        font-size: 5.6rem;
        text-align: center;
      }
    }

    div.content {
      flex-direction: column;
      align-items: center;
      position: relative;

      div.left {
        display: none;
      }

      div.right {
        width: 100%;
        padding: 0 2rem;
      }
    }
  }
`;

const PrivacyPage = () => {
  const [visibleTitles, setVisibleTitles] = useState<boolean[]>(new Array<boolean>(17).fill(false));

  const activeTitle = useMemo(() => {
    for (let i = 0; i < visibleTitles.length; i++) {
      if (visibleTitles[i]) {
        return i + 1;
      };
    }

    return null;
  }, [visibleTitles]);

  const onTitleVisible = (idx: number, inView: boolean) => {
    setVisibleTitles((prev) => {
      const d = [...prev];

      d[idx - 1] = inView;

      return d;
    })
  }

  return (
    <Container>
      <header>
        <motion.h1
          initial={{ x: -200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          Privacy Policy
        </motion.h1>

        <motion.span
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.7 }}
        >
          Updated July 17, 2022
        </motion.span>
      </header>

      <AnimateOnScroll variants={VARIANTS.slideInUp}>
        <div className="content">
          <div className="left">
            <PrivacyPolicyToc activeTitle={activeTitle} />
          </div>

          <div className="right">
            <p>
              Thank you for choosing to be part of our community at Recyclo
              ("Company," "we," "us," or "our"). We are committed to protecting
              your personal information and your right to privacy. If you have any
              questions or concerns about this privacy notice or our practices
              with regard to your personal information, please contact us at
              data@recyclo.org.uk
              <br />
              <br />
              This privacy notice describes how we might use your information if
              you:
              <br /> Download and use our mobile application — Recyclo <br />
              Engage with us in other related ways ― including any sales,
              marketing, or events
              <br /> In this privacy notice, if we refer to:
              <br /> "App," we are referring to any application of ours that
              references or links to this policy, including any listed above
              "Services," we are referring to our App, and other related services,
              including any sales, marketing, or events The purpose of this
              privacy notice is to explain to you in the clearest way possible
              what information we collect, how we use it, and what rights you have
              in relation to it. If there are any terms in this privacy notice
              that you do not agree with, please discontinue use of our Services
              immediately.
              <br />
              <br /> Please read this privacy notice carefully, as it will help
              you understand what we do with the information that we collect.
            </p>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(1, inView)}>
              <h3 className="title" id="title-1">
                1. What Information do we collect?
              </h3>

              <p>
                Personal information you disclose to us
                <br />
                <br /> In Short: We collect personal information that you provide
                to us.
                <br />
                <br /> We collect personal information that you voluntarily
                provide to us when you register on the App, express an interest in
                obtaining information about us or our products and Services, when
                you participate in activities on the App or otherwise when you
                contact us.
                <br />
                <br /> The personal information that we collect depends on the
                context of your interactions with us and the App, the choices you
                make and the products and features you use. The personal
                information we collect may include the following:
                <br />
                <br /> Personal Information Provided by You. We collect names;
                email addresses; usernames; passwords; and other similar
                information.
                <br />
                <br /> Social Media Login Data. We may provide you with the option
                to register with us using your existing social media account
                details, like your Facebook, Twitter or other social media
                account. If you choose to register in this way, we will collect
                the information described in the section called "HOW DO WE HANDLE
                YOUR SOCIAL LOGINS?" below.
                <br />
                <br /> All personal information that you provide to us must be
                true, complete and accurate, and you must notify us of any changes
                to such personal information.
                <br />
                <br /> Information collected through our App
                <br />
                <br /> In Short: We collect information regarding your mobile
                device, push notifications, when you use our App.
                <br />
                <br /> If you use our App, we also collect the following
                information:
                <br />
                <br /> Mobile Device Access. We may request access or permission
                to certain features from your mobile device, including your mobile
                device's camera, social media accounts, and other features. If you
                wish to change our access or permissions, you may do so in your
                device's settings.
                <br />
                <br /> Push Notifications. We may request to send you push
                notifications regarding your account or certain features of the
                App. If you wish to opt-out from receiving these types of
                communications, you may turn them off in your device's settings.
                <br />
                <br />
                This information is primarily needed to maintain the security and
                operation of our App, for troubleshooting and for our internal
                analytics and reporting purposes.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(2, inView)}>
              <h3 className="title" id="title-2">
                2. How do we use your information?
              </h3>

              <p>
                In Short: We process your information for purposes based on
                legitimate business interests, the fulfillment of our contract
                with you, compliance with our legal obligations, and/or your
                consent.
                <br />
                <br />
                We use personal information collected via our App for a variety of
                business purposes described below. We process your personal
                information for these purposes in reliance on our legitimate
                business interests, in order to enter into or perform a contract
                with you, with your consent, and/or for compliance with our legal
                obligations. We indicate the specific processing grounds we rely
                on next to each purpose listed below.
                <br />
                <br />
                We use the information we collect or receive:
                <br />
                <br />
                To facilitate account creation and logon process. If you choose to
                link your account with us to a third-party account (such as your
                Google or Facebook account), we use the information you allowed us
                to collect from those third parties to facilitate account creation
                and logon process for the performance of the contract. See the
                section below headed "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" for
                further information.
                <br />
                <br />
                To post testimonials. We post testimonials on our App that may
                contain personal information. Prior to posting a testimonial, we
                will obtain your consent to use your name and the content of the
                testimonial. If you wish to update, or delete your testimonial,
                please contact us at support@recyclingapp.org and be sure to
                include your name, testimonial location, and contact information.
                <br />
                <br />
                Request feedback. We may use your information to request feedback
                and to contact you about your use of our App. To enable
                user-to-user communications. We may use your information in order
                to enable user-to-user communications with each user's consent.
                <br />
                <br />
                To manage user accounts. We may use your information for the
                purposes of managing our account and keeping it in working order.
                <br />
                <br />
                To send administrative information to you. We may use your
                personal information to send you product, service and new feature
                information and/or information about changes to our terms,
                conditions, and policies.
                <br />
                <br />
                To protect our Services. We may use your information as part of
                our efforts to keep our App safe and secure (for example, for
                fraud monitoring and prevention).
                <br />
                <br />
                To enforce our terms, conditions and policies for business
                purposes, to comply with legal and regulatory requirements or in
                connection with our contract.
                <br />
                <br />
                To respond to legal requests and prevent harm. If we receive a
                subpoena or other legal request, we may need to inspect the data
                we hold to determine how to respond.
                <br />
                <br />
                Fulfill and manage your orders. We may use your information to
                fulfill and manage your orders, payments, returns, and exchanges
                made through the App.
                <br />
                <br />
                Administer prize draws and competitions. We may use your
                information to administer prize draws and competitions when you
                elect to participate in our competitions.
                <br />
                <br />
                To deliver and facilitate delivery of services to the user. We may
                use your information to provide you with the requested service.
                <br />
                <br />
                To respond to user inquiries/offer support to users. We may use
                your information to respond to your inquiries and solve any
                potential issues you might have with the use of our Services.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(3, inView)}>
              <h3 className="title" id="title-3">
                3. Will your information be shared with anyone?
              </h3>

              <p>
                In Short: We only share information with your consent, to comply
                with laws, to provide you with services, to protect your rights,
                or to fulfill business obligations.
                <br />
                <br />
                We may process or share your data that we hold based on the
                following legal basis:
                <br /> <br />
                Consent: We may process your data if you have given us specific
                consent to use your personal information for a specific purpose.
                <br /> <br />
                Legitimate Interests: We may process your data when it is
                reasonably necessary to achieve our legitimate business interests.
                <br /> <br />
                Performance of a Contract: Where we have entered into a contract
                with you, we may process your personal information to fulfill the
                terms of our contract.
                <br /> <br />
                Legal Obligations: We may disclose your information where we are
                legally required to do so in order to comply with applicable law,
                governmental requests, a judicial proceeding, court order, or
                legal process, such as in response to a court order or a subpoena
                (including in response to public authorities to meet national
                security or law enforcement requirements).
                <br /> <br />
                Vital Interests: We may disclose your information where we believe
                it is necessary to investigate, prevent, or take action regarding
                potential violations of our policies, suspected fraud, situations
                involving potential threats to the safety of any person and
                illegal activities, or as evidence in litigation in which we are
                involved.
                <br /> <br />
                More specifically, we may need to process your data or share your
                personal information in the following situations: Business
                Transfers. We may share or transfer your information in connection
                with, or during negotiations of, any merger, sale of company
                assets, financing, or acquisition of all or a portion of our
                business to another company.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(4, inView)}>
              <h3 id="title-4" className="title">
                4. How do we handle your social logins?
              </h3>

              <p>
                In Short: If you choose to register or log in to our services
                using a social media account, we may have access to certain
                information about you.
                <br />
                <br />
                Our App offers you the ability to register and login using your
                third-party social media account details (like your Facebook or
                Twitter logins). Where you choose to do this, we will receive
                certain profile information about you from your social media
                provider. The profile information we receive may vary depending on
                the social media provider concerned, but will often include your
                name, email address, friends list, profile picture as well as
                other information you choose to make public on such social media
                platforms.
                <br />
                <br />
                We will use the information we receive only for the purposes that
                are described in this privacy notice or that are otherwise made
                clear to you on the relevant App. Please note that we do not
                control, and are not responsible for, other uses of your personal
                information by your third-party social media provider. We
                recommend that you review their privacy notice to understand how
                they collect, use and share your personal information, and how you
                can set your privacy preferences on their sites and apps.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(5, inView)}>
              <h3 id="title-5" className="title">
                5. How long do we keep your information?
              </h3>

              <p>
                In Short: We keep your information for as long as necessary to
                fulfill the purposes outlined in this privacy notice unless
                otherwise required by law.
                <br />
                <br />
                We will only keep your personal information for as long as it is
                necessary for the purposes set out in this privacy notice, unless
                a longer retention period is required or permitted by law (such as
                tax, accounting or other legal requirements). No purpose in this
                notice will require us to keep your personal information for
                longer than three (3) months past the termination of the user's
                account.
                <br /> <br />
                When we have no ongoing legitimate business need to process your
                personal information, we will either delete or anonymize such
                information, or, if this is not possible (for example, because
                your personal information has been stored in backup archives),
                then we will securely store your personal information and isolate
                it from any further processing until deletion is possible.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(6, inView)}>
              <h3 className="title" id="title-6">
                6. How do we keep your information safe?
              </h3>

              <p>
                In Short: We aim to protect your personal information through a
                system of organizational and technical security measures.
                <br /> We have implemented appropriate technical and
                organizational security measures designed to protect the security
                of any personal information we process. However, despite our
                safeguards and efforts to secure your information, no electronic
                transmission over the Internet or information storage technology
                can be guaranteed to be 100% secure, so we cannot promise or
                guarantee that hackers, cybercriminals, or other unauthorized
                third parties will not be able to defeat our security, and
                improperly collect, access, steal, or modify your information.
                Although we will do our best to protect your personal information,
                transmission of personal information to and from our App is at
                your own risk. You should only access the App within a secure
                environment.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(7, inView)}>
              <h3 id="title-7" className="title">
                7. What are your privacy rights?
              </h3>

              <p>
                In Short: In some regions, such as the European Economic Area
                (EEA) and United Kingdom (UK), you have rights that allow you
                greater access to and control over your personal information. You
                may review, change, or terminate your account at any time.
                <br />
                <br />
                In some regions (like the EEA and UK), you have certain rights
                under applicable data protection laws. These may include the right
                (i) to request access and obtain a copy of your personal
                information, (ii) to request rectification or erasure; (iii) to
                restrict the processing of your personal information; and (iv) if
                applicable, to data portability. In certain circumstances, you may
                also have the right to object to the processing of your personal
                information. To make such a request, please use the contact
                details provided below. We will consider and act upon any request
                in accordance with applicable data protection laws.
                <br />
                <br />
                If we are relying on your consent to process your personal
                information, you have the right to withdraw your consent at any
                time. Please note however that this will not affect the lawfulness
                of the processing before its withdrawal, nor will it affect the
                processing of your personal information conducted in reliance on
                lawful processing grounds other than consent.
                <br />
                <br />
                If you are a resident in the EEA or UK and you believe we are
                unlawfully processing your personal information, you also have the
                right to complain to your local data protection supervisory
                authority. You can find their contact details here:
                https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                <br />
                <br />
                If you are a resident in Switzerland, the contact details for the
                data protection authorities are available here:
                https://www.edoeb.admin.ch/edoeb/en/home.html.
                <br />
                <br />
                If you have questions or comments about your privacy rights, you
                may email us at data@recyclo.org.uk Account Information
                <br />
                <br />
                If you would at any time like to review or change the information
                in your account or terminate your account, you can: Log in to your
                account settings and update your user account.
                <br />
                <br />
                Upon your request to terminate your account, we will deactivate or
                delete your account and information from our active databases.
                However, we may retain some information in our files to prevent
                fraud, troubleshoot problems, assist with any investigations,
                enforce our Terms of Use and/or comply with applicable legal
                requirements.
                <br />
                <br />
                Opting out of email marketing: You can unsubscribe from our
                marketing email list at any time by clicking on the unsubscribe
                link in the emails that we send or by contacting us using the
                details provided below. You will then be removed from the
                marketing email list — however, we may still communicate with you,
                for example to send you service-related emails that are necessary
                for the administration and use of your account, to respond to
                service requests, or for other non-marketing purposes. To
                otherwise opt-out, you may: Access your account settings and
                update your preferences.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(8, inView)}>
              <h3 id="title-8" className="title">
                8. Controls for do-not-track features
              </h3>

              <p>
                Most web browsers and some mobile operating systems and mobile
                applications include a Do-Not-Track ("DNT") feature or setting you
                can activate to signal your privacy preference not to have data
                about your online browsing activities monitored and collected. At
                this stage no uniform technology standard for recognizing and
                implementing DNT signals has been finalized. As such, we do not
                currently respond to DNT browser signals or any other mechanism
                that automatically communicates your choice not to be tracked
                online. If a standard for online tracking is adopted that we must
                follow in the future, we will inform you about that practice in a
                revised version of this privacy notice.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(9, inView)}>
              <h3 id="title-9" className="title">
                9. Do california residents have specific rights?
              </h3>

              <p>
                In Short: Yes, if you are a resident of California, you are
                granted specific rights regarding access to your personal
                information.
                <br /> <br />
                California Civil Code Section 1798.83, also known as the "Shine
                The Light" law, permits our users who are California residents to
                request and obtain from us, once a year and free of charge,
                information about categories of personal information (if any) we
                disclosed to third parties for direct marketing purposes and the
                names and addresses of all third parties with which we shared
                personal information in the immediately preceding calendar year.
                If you are a California resident and would like to make such a
                request, please submit your request in writing to us using the
                contact information provided below.
                <br /> <br />
                If you are under 18 years of age, reside in California, and have a
                registered account with the App, you have the right to request
                removal of unwanted data that you publicly post on the App. To
                request removal of such data, please contact us using the contact
                information provided below, and include the email address
                associated with your account and a statement that you reside in
                California. We will make sure the data is not publicly displayed
                on the App, but please be aware that the data may not be
                completely or comprehensively removed from all our systems (e.g.
                backups, etc.).
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(10, inView)}>
              <h3 id="title-10" className="title">
                10. Do we make updates to this notice?
              </h3>

              <p>
                In Short: Yes, we will update this notice as necessary to stay
                compliant with relevant laws.
                <br />
                <br />
                We may update this privacy notice from time to time. The updated
                version will be indicated by an updated "Revised" date and the
                updated version will be effective as soon as it is accessible. If
                we make material changes to this privacy notice, we may notify you
                either by prominently posting a notice of such changes or by
                directly sending you a notification. We encourage you to review
                this privacy notice frequently to be informed of how we are
                protecting your information.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(11, inView)}>
              <h3 id="title-11" className="title">
                11. How can you contact us about this notice?
              </h3>

              <p>
                If you have questions or comments about this notice, you may
                contact our Data Protection Officer (DPO by email at
                data@recyclo.org.uk
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(12, inView)}>
              <h3 id="title-12" className="title">
                12. How can you review, update, or delete the data we collect from
                you?
              </h3>

              <p>
                Based on the applicable laws of your country, you may have the
                right to request access to the personal information we collect
                from you, change that information, or delete it in some
                circumstances. To request to review, update, or delete your
                personal information, please submit a request form by clicking
                here.
              </p>
            </SectionInViewMonitor>
          </div>
        </div>
      </AnimateOnScroll>  
    </Container>
  );
};

export default PrivacyPage;
