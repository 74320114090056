import React from "react";
import styled from "styled-components";
import { DESKTOP_MIN_WIDTH } from "../utils/constants";

const TextInput = styled.div`
  border-radius: 8px;
  background: #f8f9fa;
  padding: 1.8rem 2.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  div.prefix {
    margin-right: 1rem;
  }

  input {
    display: block;
    width: 100%;
    background-color: #f8f9fa;
    outline: none;
    border: none;
    color: #1c344f;
    font-size: 2.4rem;
    line-height: 2.9rem;
    flex: 1;

    &::placeholder {
      color: #aeb8c1;
    }
  }

  @media screen and (max-width: ${DESKTOP_MIN_WIDTH}) {
    padding: 1.4rem 2rem;

    img {
      width: 1.8rem;
    }

    input {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }
`;

export interface InputComponentProps {
  id: string;
  type: string;
  placeholder?: string;
  prefixIcon?: JSX.Element;
  required?: boolean;
  value: string;
  onChange: (str: string) => void;
}

const InputComponent: React.FC<InputComponentProps> = ({
  id,
  type = "text",
  placeholder = "",
  prefixIcon,
  required = false,
  value,
  onChange,
}) => {
  return (
    <TextInput className="input-wrapper">
      {prefixIcon && <div className="prefix">{prefixIcon}</div>}
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        min={0}
        onChange={(e) => onChange(e.target.value)}
        required={required}
      />
    </TextInput>
  );
};

export default InputComponent;
