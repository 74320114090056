import React from "react";
import styled from "styled-components";
import { DESKTOP_MIN_WIDTH } from "../utils/constants";

const TextInput = styled.textarea`
  border-radius: 8px;
  background: #f8f9fa;
  padding: 1.8rem 2.4rem;
  width: 100%;
  outline: none;
  border: none;
  color: #1c344f;
  font-size: 2.4rem;
  line-height: 2.9rem;

  &::placeholder {
    color: #aeb8c1;
  }

  @media screen and (max-width: ${DESKTOP_MIN_WIDTH}) {
    padding: 1.4rem 2rem;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`;

export interface TextAreaComponentProps {
  id: string;
  placeholder?: string;
  prefixIcon?: JSX.Element;
  required?: boolean;
  value: string;
  onChange: (str: string) => void;
}

const TextAreaComponent: React.FC<TextAreaComponentProps> = ({
  id,
  placeholder = "",
  required = false,
  value,
  onChange,
}) => {
  return (
    <TextInput
      className="input-wrapper"
      placeholder={placeholder}
      id={id}
      rows={6}
      required={required}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default TextAreaComponent;
