import { Variants } from "framer-motion";

export const DESKTOP_MIN_WIDTH = "900px";
export const LARGE_SCREEN_MIN_WIDTH = "1800px";

export const API_BASE_URL = "https://shrouded-gorge-32227.herokuapp.com/api/v1";

export const VARIANTS: { [key: string]: Variants } = {
    slideInLeft: {
        visible: { opacity: 1, x: 0, transition: { duration: 1 } },
        hidden: { opacity: 0, x: -200 },
    },
    slideInRight: {
        visible: { opacity: 1, x: 0, transition: { duration: 1 } },
        hidden: { opacity: 0, x: 200 },
    },
    slideInUp: {
        visible: { opacity: 1, y: 0, transition: { duration: 1 } },
        hidden: { opacity: 0, y: 200 },
    },
    scaleIn: {
        visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
        hidden: { opacity: 0, scale: 0 },
    },
    fadeIn: {
        visible: { opacity: 1, transition: { duration: 1, delay: 0.5 } },
        hidden: { opacity: 0 },
    },
}