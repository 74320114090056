import { useState } from "react";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import styled from "styled-components";
import image1 from "../assets/svg/image1.svg";
import image2 from "../assets/svg/image2.svg";
import CustomButton from "../components/cutom-button.component";
import StoreButton from "../components/store-button.component";

import benefit1 from "../assets/svg/benefit1.svg";
import benefit2 from "../assets/svg/benefit2.svg";
import benefit3 from "../assets/svg/benefit3.svg";
import benefit4 from "../assets/svg/benefit4.svg";
import benefit5 from "../assets/svg/benefit5.svg";
import blurbg1 from "../assets/svg/blur_background1.svg";

import BenefitsNav from "../components/benefits-nav.component";
import { DESKTOP_MIN_WIDTH, LARGE_SCREEN_MIN_WIDTH, VARIANTS } from "../utils/constants";
import { Benefit as BenefitComponent } from "../shared/style";
import Animations from "../utils/Animations";
import { motion } from "framer-motion";
import AnimateOnScroll from "../components/AnimateOnScroll";

const storeButtonProps = {
  colorSub: "#5C6A78",
  colorMain: "#1C344F",
  hoverColorMain: "#ffffff",
  hoverColorSub: "#cbd1d7",
  bgColor: "none",
  hoverBgColor: "#6955EB",
  iconColor: "#6955EB",
  hoverIconColor: "#ffffff",
  borderColor: "#CBD1D7",
  hoverBorderColor: "#6955EB",
};

export interface Benefit {
  title: string;
  text: string;
}

const benefits: Benefit[] = [
  {
    title: "Recycle from home",
    text: "Schedule home withdrawals for the time you prefer. Forget containers and waiting",
  },
  {
    title: "Unlimited traceability",
    text: "Track scheduled withdrawals and check their status at any time",
  },
  {
    title: "Absolute simplicity",
    text: "Transform complex collection services into a guided experience tailored to your needs.",
  },
  {
    title: "Direct communication",
    text: "Gone are the days when you couldn't communicate with your collectors and customers services.",
  },
  {
    title: "Disruptive technology for responsible citizens",
    text: "Greener Smart Cities improves your services while reducing logistics, administrative and final disposal costs.",
  },
];

const Container = styled.div`
  padding: 0;
  width: 100%;
  overflow-x: hidden;

  div.recyclo-benefits {
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff;

    h1.title {
      font-weight: 600;
      font-size: 8.6rem;
      line-height: 10rem;
      letter-spacing: -0.06em;
      color: #1c344f;
      width: 60%;
    }

    span {
      &.benefit {
        color: #6955eb;
        font-size: 1.8rem;
        line-height: 2.2rem;
        text-transform: uppercase;
      }
    }

    div.benefits-container-static {
      display: none;
    }

    div.benefits-container {
      display: flex;
      flex-direction: row;
      gap: 6rem;

      div {
        flex: 1;

        img {
          display: block;
          width: 80%;
          max-width: 600px;
          margin: auto;
        }
      }
    }
  }

  div.grid-wrapper {
    padding: 10rem 5%;
    width: 100%;
    position: relative;

    img.blur-bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }

    div.grid {
      z-index: 1;
      // display: grid;
      // grid-template-columns: 50% 50%;
      display: flex;
      flex-direction: row;
      gap: 2rem;
      // max-width: 150rem;
      margin-left: auto;
      margin-right: auto;

      div.grid-item {
        margin: 2rem;
        margin-right: 0;

        h1 {
          font-weight: 600;
          font-size: 8.6rem;
          line-height: 10rem;
          letter-spacing: -0.06em;
          color: #1c344f;

          &.are-you-in-need {
            font-size: 6.4rem;
            line-height: 7.7rem;
          }
        }

        span {
          &.grid-1-info,
          &.grid-3-info {
            margin-top: 2rem;
            display: block;
            font-weight: 400;
            font-size: 2rem;
            line-height: 2.4rem;
            color: #748595;
          }

          &.donation {
            color: #55b653;
            font-size: 1.8rem;
            line-height: 2.2rem;
          }
        }

        img.image1 {
          display: block;
          margin: auto;
          width: 100%;
        }

        div.store-buttons {
          display: flex;
          display-direction: row;
          align-items: center;
          margin-top: 3rem;
          gap: 2rem;
        }
      }
    }
  }

  @media screen and (min-width: ${LARGE_SCREEN_MIN_WIDTH}) {
    div.grid-wrapper {
      padding: 10rem 15%;

      div.grid {
        div.grid-item {
          h1 {
            font-size: 10.4rem;
            line-height: 12.5rem;
          }

          span {
            &.grid-1-info,
            &.grid-3-info {
              font-size: 2.4rem;
              line-height: 3.2rem;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${DESKTOP_MIN_WIDTH}) {
    div.recyclo-benefits {
      h1.title {
        font-size: 3.2rem;
        line-height: 3.9rem;
        width: 100%;
      }

      span {
        &.benefit {
          font-size: 1.6rem;
          line-height: 2.4rem;
        }
      }

      div.benefits-container {
        display: none;
      }

      div.benefits-container-static {
        display: block;

        div {
          margin-bottom: 2rem;

          &:last-child {
            margin-botttom: 0;
          }

          img {
            object-fit: contain;
            width: 100%;
          }
        }
      }
    }

    div.grid-wrapper {
      padding: 4rem 2rem;

      div.grid {
        // grid-template-columns: 100%;
        flex-direction: column;
        gap: 4rem;

        div.grid-item {
          margin: 0;

          h1 {
            font-size: 4rem;
            line-height: 4.8rem;

            &.are-you-in-need {
              font-size: 3.2rem;
              line-height: 3.9rem;
            }
          }

          span {
            &.grid-1-info,
            &.grid-3-info {
              font-size: 1.6rem;
              line-height: 2.4rem;
            }

            &.donation {
              font-size: 1.2rem;
              line-height: 1.5rem;
            }
          }

          img.image1 {
            width: 100%;
          }

          div.store-buttons {
            display: flex;
            display-direction: row;
            align-items: center;
            margin-top: 3rem;
            gap: 2rem;
          }
        }
      }
    }
  }
`;

const HomePage = () => {
  let easeing = [0.6, -0.05, 0.01, 0.99];

  const { fadeInUp } =
    new Animations();

  const [activeBenefit, setActiveBenefit] = useState<number>(0);

  const getBenefitImage = () => {
    switch (activeBenefit) {
      case 0:
        return benefit1;

      case 1:
        return benefit2;

      case 2:
        return benefit3;

      case 3:
        return benefit4;

      case 4:
        return benefit5;

      default:
        return null;
    }
  };

  const getStaticBenefitImage = (idx: number) => {
    switch (idx) {
      case 0:
        return benefit1;

      case 1:
        return benefit2;

      case 2:
        return benefit3;

      case 3:
        return benefit4;

      case 4:
        return benefit5;

      default:
        return null;
    }
  };

  return (
    <Container>
      <motion.div 
        className="grid-wrapper"
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3, ease: easeing }}
      >
        <img src={blurbg1} alt="" className="blur-bg" />

        <div className="grid">
          <motion.div 
            className="grid-item"
            initial={{ x: -200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <motion.h1
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.9, ease: easeing, delay: 0.6 }}
            >
              The best system to recycling
            </motion.h1>

            <motion.span 
              className="grid-1-info"
              initial="initial"
              animate="animate"
              variants={fadeInUp()}
            >
              Recyclo is the platform that connects generators, collectors and
              governments to transform waste into resources.
            </motion.span>

            <div className="store-buttons">
              <StoreButton
                {...storeButtonProps}
                storeName="App Store"
                icon={<FaApple className="icon" />}
              />

              <StoreButton
                {...storeButtonProps}
                storeName="Google Play"
                icon={<FaGooglePlay className="icon" />}
              />
            </div>
          </motion.div>

          <div className="grid-item">
            <img className="image1" src={image1} alt="" />
          </div>
        </div>
      </motion.div>

      <div className="grid-wrapper" style={{ backgroundColor: "#F1F9F1" }}>
        <div className="grid">
          <AnimateOnScroll variants={VARIANTS.slideInRight}>
            <div className="grid-item">
              <img className="image1" src={image2} alt="" />
            </div>
          </AnimateOnScroll>

          <AnimateOnScroll variants={VARIANTS.slideInLeft}>
            <div className="grid-item">
              <span className="donation">DONATION</span>

              <h1>Join the cause. Make the world cleaner.</h1>

              <div style={{ height: "4rem" }} />

              <CustomButton title="Donate now" />
            </div>
          </AnimateOnScroll>
        </div>
      </div>

      <div className="grid-wrapper">
        <div className="recyclo-benefits">
          <span className="benefit">Benefits</span>

        <AnimateOnScroll variants={{...VARIANTS.scaleIn, visible: { ...VARIANTS.scaleIn.visible, transition: { duration: 0.5 } } }}>
          <h1 className="title">
            You are one step away from throwing away less and saving more
          </h1>
        </AnimateOnScroll>

          <div style={{ height: "12rem" }} />

          <div className="benefits-container">
            <AnimateOnScroll variants={VARIANTS.fadeIn}>
              <div>
                <BenefitsNav
                  activeBenefit={activeBenefit}
                  setActiveBenefit={setActiveBenefit}
                  benefits={benefits}
                />
              </div>
            </AnimateOnScroll>

            <AnimateOnScroll variants={VARIANTS.fadeIn}>
              <div>
                <img src={getBenefitImage()} alt="" />
              </div>
            </AnimateOnScroll>
          </div>

          <div className="div benefits-container-static">
            {benefits.map((benefit, index) => {
              return (
                <div>
                  <BenefitComponent
                    key={`benefit-${index}-${benefit.title}`}
                    active
                  >
                    <h1>{benefit.title}</h1>

                    <span>{benefit.text}</span>
                  </BenefitComponent>

                  <img src={getStaticBenefitImage(index)} alt="" />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div
        className="grid-wrapper"
        style={{ backgroundColor: "#F1F9F1", marginBottom: "8rem" }}
      >
        <div className="grid">
          <AnimateOnScroll variants={VARIANTS.slideInRight}>
            <div className="grid-item">
              <h1 className="are-you-in-need">
                Are you in need of recylable products?
              </h1>
            </div>
          </AnimateOnScroll>

          <AnimateOnScroll variants={VARIANTS.slideInLeft}>
            <div className="grid-item">
              <span className="grid-3-info">
                Are you a recycling company in need of recyclable products? You
                can contact us so we can add you to our client list and you can
                start doing business with us.
              </span>

              <div style={{ height: "4rem" }} />

              <CustomButton title="Contact us" />
            </div>
          </AnimateOnScroll>
        </div>
      </div>
    </Container>
  );
};

export default HomePage;
