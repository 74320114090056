import styled from "styled-components";
import { DESKTOP_MIN_WIDTH, LARGE_SCREEN_MIN_WIDTH } from "../utils/constants";

interface StoreButtonProps {
  colorSub: string;
  hoverColorSub: string;
  colorMain: string;
  hoverColorMain: string;
  bgColor: string;
  hoverBgColor: string;
  iconColor: string;
  hoverIconColor: string;
  storeName: string;
  borderColor: string;
  hoverBorderColor: string;
  icon: JSX.Element;
}

const Wrapper = styled.button<StoreButtonProps>`
  outline: none;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 56px;
  padding: 1rem 2rem;
  background: ${(props) => props.bgColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  transition: 0.3s;
  z-index: 999;

  &:hover {
    background: ${(props) => props.hoverBgColor};
    border-color: ${(props) => props.hoverBorderColor};

    .icon {
      color: ${(props) => props.hoverIconColor};
    }

    div.info {
      span {
        color: ${(props) => props.hoverColorMain};

        &:nth-child(1) {
          color: ${(props) => props.hoverColorSub};
        }
      }
    }
  }

  .icon {
    display: block;
    color: ${(props) => props.iconColor};
    font-size: 3.6rem;
    transition: 0.3s;
  }

  div.info {
    span {
      text-align: left;
      display: block;
      color: ${(props) => props.colorMain};
      font-size: 2.4rem;
      line-height: 2.9rem;
      transition: 0.3s;

      &:nth-child(1) {
        font-size: 1.4rem;
        line-height: 2rem;
        color: ${(props) => props.colorSub};
      }
    }
  }

  @media screen and (min-width: ${LARGE_SCREEN_MIN_WIDTH}) {
    width: 296px;
    height: 110px;

    .icon {
      display: block;
      color: ${(props) => props.iconColor};
      font-size: 4.6rem;
      transition: 0.3s;
    }
  }

  @media screen and (max-width: ${DESKTOP_MIN_WIDTH}) {
    .icon {
      font-size: 3rem;
    }

    div.info {
      span {
        font-size: 1.4rem;
        line-height: 2rem;

        &:nth-child(1) {
          font-size: 1rem;
          line-height: 1.4rem;
        }
      }
    }
  }
`;

const StoreButton: React.FC<StoreButtonProps> = (props) => {
  return (
    <Wrapper {...props}>
      {props.icon}

      <div className="info">
        <span>GET IT ON</span>

        <span>{props.storeName}</span>
      </div>
    </Wrapper>
  );
};

export default StoreButton;
