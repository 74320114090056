import styled from "styled-components";
import InputComponent from "../components/textfield.component";
import { DESKTOP_MIN_WIDTH, LARGE_SCREEN_MIN_WIDTH, VARIANTS } from "../utils/constants";
import searchIcon from "../assets/svg/search.svg";
import Faq from "../components/faq.component";
import { useState } from "react";
import faqImage1 from "../assets/svg/faqImage1.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "../utils/routes";
import { motion } from "framer-motion";
import AnimateOnScroll from "../components/AnimateOnScroll";

const Container = styled.div`
  padding: 0;
  width: 100%;
  position: relative;
  overflow-x: hidden;

  div.faq-content {
    padding: 10rem 5%;
    padding-bottom: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1.faq-title {
      text-align: center;
      font-weight: 600;
      font-size: 7.6rem;
      line-height: 9.6rem;
      letter-spacing: -0.04em;
      color: #1c344f;
    }

    span {
      font-size: 2.4rem;
      line-height: 3.2rem;
      color: #47525c;
      margin-bottom: 2rem;
    }

    div.input-wrapper {
      width: 80%;
      max-width: 902px;
      margin-bottom: 5rem;
    }

    div.faqs {
      margin-top: 4rem;
      margin-bottom: 10rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    span.extra {
      margin-top: 2rem;
      font-size: 2rem;
      line-height: 2.4rem;
      color: #5c6a78;

      a {
        color: #55b653;
        padding-bottom: 0.2rem;
        border-bottom-width: 1px;
        text-decoration: none;
        border-bottom-style: solid;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: ${DESKTOP_MIN_WIDTH}) {
    div.faq-content {
      padding: 2rem;
      padding-bottom: 10rem;
      align-items: flex-start;

      h1.faq-title {
        text-align: start;
        font-size: 3.2rem;
        line-height: 3.9rem;
        letter-spacing: -0.04em;
      }

      span {
        text-align: start;
        font-size: 1.6rem;
        line-height: 3.2rem;
      }

      div.input-wrapper {
        width: 100%;
        max-width: unset;
        margin-bottom: 5rem;
      }

      div.faqs {
        margin-top: 0;
        margin-bottom: 10rem;
      }

      img {
        margin-left: auto;
        margin-right: auto;
      }

      span.extra {
        text-align: center;
      }
    }
  }

  @media screen and (min-width: ${LARGE_SCREEN_MIN_WIDTH}) {
    div.faq-content {
      padding: 10rem 15%;

      h1.faq-title {
        font-size: 9.6rem;
        line-height: 11.6rem;
      }
    }
  }
`;

const faqs = [
  {
    title: "What is Recyclo?",
    text: "Recycling benefits businesses by reducing their raw material costs, maximizing profitability, and reducing the company's carbon footprint. Additionally, recycling can help create a positive workplace culture, something that attracts top talent.",
  },
  {
    title: "How can I schedule a pickup?",
    text: "Recycling benefits businesses by reducing their raw material costs, maximizing profitability, and reducing the company's carbon footprint. Additionally, recycling can help create a positive workplace culture, something that attracts top talent.",
  },
  {
    title: "Do I need to pay to schedule a pickup?",
    text: "Recycling benefits businesses by reducing their raw material costs, maximizing profitability, and reducing the company's carbon footprint. Additionally, recycling can help create a positive workplace culture, something that attracts top talent.",
  },
  {
    title: "Are there limits to pickup?",
    text: "Recycling benefits businesses by reducing their raw material costs, maximizing profitability, and reducing the company's carbon footprint. Additionally, recycling can help create a positive workplace culture, something that attracts top talent.",
  },
  {
    title: "Can I refer my friends?",
    text: "Recycling benefits businesses by reducing their raw material costs, maximizing profitability, and reducing the company's carbon footprint. Additionally, recycling can help create a positive workplace culture, something that attracts top talent.",
  },
];

const easeing = [0.6, -0.05, 0.01, 0.99];

const FaqPage = () => {
  const [activeFaqs, setActiveFaqs] = useState<number[]>([]);
  const [query, setQuery] = useState<string>("");

  const handleFaqClick = (index: number) => () => {
    if (activeFaqs.includes(index)) {
      setActiveFaqs((prev) => prev.filter((e) => e !== index));
    } else {
      setActiveFaqs((prev) => [...prev, index]);
    }
  };

  return (
    <Container>
      <motion.div 
        className="faq-content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: easeing }}
      >
        <motion.h1 
          className="faq-title"
          initial={{ x: -200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          Frequently Asked Questions!
        </motion.h1>

        <motion.span
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.7 }}
        >
          Have any questions? We’re here to help
        </motion.span>

        <motion.div 
          className="input-wrapper"
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.7, delay: 0.9 }}
        >
          <InputComponent
            id="faq-search"
            placeholder="Search here"
            type="texr"
            prefixIcon={<img src={searchIcon} alt="search" />}
            value={query}
            onChange={setQuery}
          />
        </motion.div>

        <div className="faqs">
          {faqs.map((faq, idx) => {
            return (
              <Faq
                key={"faq-" + idx}
                title={faq.title}
                text={faq.text}
                isOpen={activeFaqs.includes(idx)}
                onClick={handleFaqClick(idx)}
              />
            );
          })}
        </div>

        <AnimateOnScroll variants={VARIANTS.fadeIn}>
          <img src={faqImage1} alt="" />
        </AnimateOnScroll>

        <AnimateOnScroll variants={VARIANTS.fadeIn}>
          <span className="extra">
            Still have questions? Kindly{" "}
            <Link to={ROUTES.contact_us}>contact us.</Link>
          </span>
        </AnimateOnScroll>
      </motion.div>
    </Container>
  );
};

export default FaqPage;
