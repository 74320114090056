import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import styled from "styled-components";
import { DESKTOP_MIN_WIDTH, VARIANTS } from "../utils/constants";
import AnimateOnScroll from "./AnimateOnScroll";

interface ContainerProps {
  isOpen: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  transition: 0.3s;
  padding: 3rem;
  padding-top: 0;
  border-bottom: 1px solid #cbd1d7;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  h1 {
    width: 100%;
    text-align: start;
    font-size: 3.2rem;
    line-height: 3.9rem;

    span {
      margin: 0;
      padding: 0;
      margin-right: 4rem;
      display: inline-block;
      cursor: pointer;

      .icon {
        color: #55b653;
      }
    }
  }

  div.faq-content-single {
    height: ${(props) => (props.isOpen ? "unset" : "0")};
    opacity: ${(props) => (props.isOpen ? "1" : "0")};
    margin-left: 4rem;
    margin-right: 4rem;
    padding-left: 2rem;
    transition: 0.3s;
    border-left: 3px solid #6955eb;
    font-size: 2rem;
    line-height: 2.4rem;
    color: #5c6a78;
  }

  @media screen and (max-width: ${DESKTOP_MIN_WIDTH}) {
    padding: 1rem;
    padding-top: 0;

    h1 {
      font-size: 1.6rem;
      line-height: 1.9rem;

      span {
        margin-right: 2rem;
      }
    }

    div.faq-content-single {
      margin-right: 0;
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }
`;

export interface FaqProps {
  title: string;
  text: string;
  isOpen: boolean;
  onClick: () => void;
}

const Faq: React.FC<FaqProps> = ({ isOpen = false, title, text, onClick }) => {
  return (
    <AnimateOnScroll variants={VARIANTS.slideInRight}>
      <Container isOpen={isOpen}>
        <h1>
          <span onClick={onClick}>
            {isOpen ? <FaMinus className="icon" /> : <FaPlus className="icon" />}
          </span>

          {title}
        </h1>

        <div className="faq-content-single">
          <p>{text}</p>
        </div>
      </Container>
    </AnimateOnScroll>
  );
};

export default Faq;
