import { JSXElementConstructor, ReactElement, useState } from "react";
import styled from "styled-components";
import logo from "../assets/svg/new-logo.svg";
import cancelIcon from "../assets/svg/cancel.svg";
import menu from "../assets/svg/menu_icon.svg";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../utils/routes";
import Footer from "./footer";

const Container = styled.div`
  position: relative;
  height: 100vh;
  overflow-y: auto;

  header.nav-head {
    padding: 2rem 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid #e8ebed;
    height: 80px;
    z-index: 1;

    img {
      width: 100px;
      display: block;

      &.menu {
        width: 24px;
        cursor: pointer;
        margin-left: auto;
      }
    }
  }
`;

const Sidebar = styled.section`
  position: absolute;
  width: 100%;
  background: #fff;
  right: 100%;
  transition: 0.5s;
  z-index: 1;
  &.active {
    right: 0;
  }
  ul {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    list-style-type: none;
    li {
      padding: 2rem 0;
      a,
      button {
        border: none;
        background: transparent;
        font-size: 1.6rem;
        color: #748595;
        text-decoration: none;
        &.active {
          color: #224f21;
        }
      }
    }
  }
`;

export interface MobileNavbarProps {
  Outlet: (
    props: any
  ) => ReactElement<any, string | JSXElementConstructor<any>> | null;
}

const MobileNavbar: React.FC<MobileNavbarProps> = ({ Outlet }) => {
  const [isSlidebarOpen, setIsSlideBarOpen] = useState(false);

  const toggleSlide = () => setIsSlideBarOpen((prev) => !prev);

  return (
    <Container>
      <header className="nav-head">
        <img src={logo} alt="Recyclo logo" />

        <img
          src={isSlidebarOpen ? cancelIcon : menu}
          className="menu"
          onClick={toggleSlide}
        />
      </header>

      <Sidebar className={`${isSlidebarOpen && "active"}`}>
        <ul onClick={() => setIsSlideBarOpen(false)}>
          <li>
            <NavLink to={ROUTES.home}>Home</NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.about}>About</NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.faq}>FAQ</NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.contact_us}>Contact Us</NavLink>
          </li>
        </ul>
      </Sidebar>

      {!isSlidebarOpen && <Outlet />}

      {!isSlidebarOpen && <Footer type="MOBILE" />}
    </Container>
  );
};

export default MobileNavbar;
