import React from "react";
import styled from "styled-components";

interface ContainerProps {
  selected: boolean;
}

const Container = styled.div<ContainerProps>`
  padding: 2rem;
  background-color: ${(props) => (props.selected ? "#F1F9F1" : "none")};
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #000000;
  text-transform: capitalize;
  border: 1px solid ${(props) => (props.selected ? "#55B653" : "#e8ebed")};
  border-radius: 3.2rem;
  transition: 0.3s;
  cursor: pointer;
`;

export interface TopicProps {
  selected: boolean;
  text: string;
  onClick: () => void;
}

const Topic: React.FC<TopicProps> = ({ selected, text, onClick }) => {
  return (
    <Container selected={selected} onClick={onClick}>
      {text}
    </Container>
  );
};

export default Topic;
