import React, { JSXElementConstructor, ReactElement } from "react";
import styled from "styled-components";
import logo from "../assets/svg/new-logo.svg";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../utils/routes";
import { COLORS } from "../utils/colors";
import { useLocation } from "react-router-dom";
import Footer from "./footer";
import { motion } from "framer-motion";
import Animations from "../utils/Animations";

export interface DesktopNavBarProps {
  Outlet: (
    props: any
  ) => ReactElement<any, string | JSXElementConstructor<any>> | null;
}

const Container = styled.div`
  position: relative;
  height: 100vh;
  overflow-y: auto;

  header.main {
    padding: 2rem 6rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 9999;
    height: 80px;

    img {
      width: 150px;
      display: block;
    }

    nav {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2.5rem;
    }
  }
`;

interface NavLinkContainerProps {
  active: boolean;
}

const NavLinkContainer = styled.div<NavLinkContainerProps>`
  a {
    text-decoration: none;
    color: ${(props) => (props.active ? COLORS.grey_main : COLORS.grey_600)};
    font-weight: ${(props) => (props.active ? "500" : "400")};
    font-size: 1.8rem;
  }
`;

const DesktopNavBar: React.FC<DesktopNavBarProps> = ({ Outlet }) => {
  const location = useLocation();

  const { stagger, fadeInUp } = new Animations();

  return (
    <Container>
      <motion.header variants={stagger()} className="main">
        <motion.div
          initial="initial"
          animate="animate"
          variants={fadeInUp()}
        >
          <NavLink to={ROUTES.home}>
            <img src={logo} alt="Recyclo logo" />
          </NavLink>
        </motion.div>

        <motion.nav
          initial="initial"
          animate="animate"
          variants={fadeInUp()}
        >
          <NavLinkContainer active={location.pathname === ROUTES.home}>
            <NavLink to={ROUTES.home} className="nav-link">
              Home
            </NavLink>
          </NavLinkContainer>

          <NavLinkContainer active={location.pathname === ROUTES.about}>
            <NavLink to={ROUTES.about} className="nav-link">
              About
            </NavLink>
          </NavLinkContainer>

          <NavLinkContainer active={location.pathname === ROUTES.faq}>
            <NavLink to={ROUTES.faq} className="nav-link">
              FAQ
            </NavLink>
          </NavLinkContainer>

          <NavLinkContainer active={location.pathname === ROUTES.contact_us}>
            <NavLink to={ROUTES.contact_us} className="nav-link">
              Contact Us
            </NavLink>
          </NavLinkContainer>
        </motion.nav>
      </motion.header>

      <Outlet />

      <Footer type={"DESKTOP"} />
    </Container>
  );
};

export default DesktopNavBar;
