import React from "react";
import { useHeadingsData } from "../hooks/useHeadingsData";

interface Props {
  activeTitle?: number | null;
}

const PrivacyPolicyToc: React.FC<Props> = ({ activeTitle }) => {
  const { nestedHeadings } = useHeadingsData();

  return (
    <ul>
      {nestedHeadings.map((heading, idx) => {
        return (
          <li key={heading.id}>
            <a
              href={`#${heading.id}`}
              className={activeTitle === idx + 1 ? "selected" : undefined}
              onClick={(e) => {
                e.preventDefault();
                document.querySelector(`#${heading.id}`)?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
            >
              {heading.title[0].toUpperCase() +
                heading.title.substring(1).toLowerCase()}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default PrivacyPolicyToc;
