import { FaApple, FaGooglePlay } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SocialLinks } from ".";
import { ROUTES } from "../../utils/routes";
import StoreButton from "../store-button.component";

const Container = styled.div`
  padding: 3rem 6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;

  div.flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
`;

const buttonProps = {
  colorSub: "#cbd1d7",
  colorMain: "#ffffff",
  hoverColorMain: "#1C344F",
  hoverColorSub: "#5C6A78",
  bgColor: "none",
  hoverBgColor: "#ffffff",
  iconColor: "#ffffff",
  hoverIconColor: "#1C344F",
  borderColor: "#748595",
  hoverBorderColor: "#ffffff",
};

export const DesktopFooter = () => {
  return (
    <Container>
      <span>© 2022 Recyclo | All Rights Reserved</span>

      <span>
        <Link to={ROUTES.terms_of_service}>Terms of Service</Link>
      </span>

      <span>
        <Link to={ROUTES.privacy_policy}>Privacy Policy</Link>
      </span>

      <span>
        <SocialLinks />
      </span>

      <div className="flex">
        <span>
          <StoreButton
            {...buttonProps}
            storeName="App Store"
            icon={<FaApple className="icon" />}
          />
        </span>

        <span>
          <StoreButton
            {...buttonProps}
            storeName="Google Play"
            icon={<FaGooglePlay className="icon" />}
          />
        </span>
      </div>
    </Container>
  );
};
