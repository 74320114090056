import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout.component";
import AboutPage from "./pages/about.page";
import ContactUsPage from "./pages/contact-us.page";
import FaqPage from "./pages/faq.page";
import HomePage from "./pages/home.page";
import PrivacyPage from "./pages/privacy.page";
import TermsPage from "./pages/terms.page";
import { ROUTES } from "./utils/routes";

function App() {
  return (
    <Routes>
      <Route path={ROUTES.home} element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path={ROUTES.about} element={<AboutPage />} />
        <Route path={ROUTES.faq} element={<FaqPage />} />
        <Route path={ROUTES.contact_us} element={<ContactUsPage />} />
        <Route path={ROUTES.terms_of_service} element={<TermsPage />} />
        <Route path={ROUTES.privacy_policy} element={<PrivacyPage />} />
      </Route>
    </Routes>
  );
}

export default App;
