import styled from "styled-components";
import { DESKTOP_MIN_WIDTH } from "../utils/constants";

const Container = styled.button`
  outline: none;
  border: none;
  background-color: #55b653;
  border-radius: 56px;
  padding: 2rem 4rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: #ffffff;
  text-transform: uppercase;
  transition: 0.3s;

  width: 287px;
  height: 90px;

  @media screen and (max-width: ${DESKTOP_MIN_WIDTH}) {
    height: unset;
    width: unset;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  &:hover {
    background-color: #429740;
  }
`;

interface CustomButtonProps {
  title: string;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  title,
  type = "button",
  disabled = false,
}) => {
  return (
    <Container type={type} disabled={disabled}>
      {title}
    </Container>
  );
};

export default CustomButton;
