import React from "react";
import styled from "styled-components";
import { DesktopFooter } from "./desktop-footer.component";
import { MobileFooter } from "./mobile-footer.component";
import { FaLinkedin, FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { DESKTOP_MIN_WIDTH } from "../../utils/constants";

const Container = styled.div`
  background-color: #1c344f;
  color: #ffffff;
  font-size: 1.8rem;

  a {
    text-decoration: none;
    color: #ffffff;
    font-size: 1.8rem;

    transition: 0.3s;

    &:hover {
      color: #55b653;
    }
  }

  @media screen and (max-width: ${DESKTOP_MIN_WIDTH}) {
    font-size: 1.6rem;

    a {
      font-size: 1.6rem;
    }
  }
`;

const SocialLinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.6rem;

  .icon {
    font-size: 2.5rem;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: #55b653;
    }
  }
`;

export interface FooterProps {
  type: "MOBILE" | "DESKTOP";
}

export const SocialLinks = () => {
  return (
    <SocialLinksContainer>
      <FaLinkedin className="icon" />

      <FaFacebook className="icon" />

      <FaTwitter className="icon" />

      <FaInstagram className="icon" />
    </SocialLinksContainer>
  );
};

const Footer: React.FC<FooterProps> = ({ type }) => {
  return (
    <Container>
      {type === "DESKTOP" ? <DesktopFooter /> : <MobileFooter />}
    </Container>
  );
};

export default Footer;
