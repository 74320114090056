import { useEffect, useState } from "react";
import { DESKTOP_MIN_WIDTH } from "../utils/constants";

export const useShowDesktopVersion = () => {
  const mediaQuery = window.matchMedia(`(min-width: ${DESKTOP_MIN_WIDTH})`);
  const [showDesktopVersion, setShowDesktopVersion] = useState(
    mediaQuery.matches
  );

  useEffect(() => {
    window.addEventListener("resize", () => {
      setShowDesktopVersion(mediaQuery.matches);
    });
  }, [mediaQuery.matches]);

  return { showDesktopVersion };
};
