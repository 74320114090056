import { useShowDesktopVersion } from "../hooks/useShowDesktopVersion";
import DesktopNavBar from "./desktop-navbar.component";
import { Outlet } from "react-router-dom";
import MobileNavbar from "./mobile-navbar";

const Layout = () => {
  const { showDesktopVersion } = useShowDesktopVersion();

  return (
    <div>
      {showDesktopVersion ? (
        <DesktopNavBar Outlet={Outlet} />
      ) : (
        <MobileNavbar Outlet={Outlet} />
      )}
    </div>
  );
};

export default Layout;
