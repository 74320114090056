import styled from "styled-components";
import { DESKTOP_MIN_WIDTH } from "../utils/constants";

interface BenefitProps {
  active: boolean;
}

const Benefit = styled.div<BenefitProps>`
  border-left: 4px solid ${(props) => (props.active ? "#6955EB" : "#E8EBED")};
  transition: 0.3s;
  padding-top: 3rem;
  padding-left: 3rem;
  cursor: pointer;

  &:hover {
    border-color: #6955eb;

    h1.benefit-title {
      color: #1c344f;
    }

    span.benefit-text {
      display: block;
    }
  }

  h1.benefit-title {
    font-weight: 600;
    font-size: 3.2rem;
    line-height: 3.9rem;
    letter-spacing: -0.04em;
    color: ${(props) => (props.active ? "#1c344f" : "#748595")};
    width: 60%;
    transition: 0.3s;
  }

  span.benefit-text {
    display: ${(props) => (props.active ? "block" : "none")};
    font-size: 2rem;
    line-height: 2.4rem;
    color: #5c6a78;
    transition: 0.3s;
  }

  @media screen and (max-width: ${DESKTOP_MIN_WIDTH}) {
    padding-top: 0.5rem;
    padding-left: 0.5rem;
  }
`;

export { Benefit };
