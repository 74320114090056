import styled from "styled-components";
import ContactForm from "../components/forms/contact.form";
import { DESKTOP_MIN_WIDTH, LARGE_SCREEN_MIN_WIDTH, VARIANTS } from "../utils/constants";
import { motion } from "framer-motion";
import AnimateOnScroll from "../components/AnimateOnScroll";

const Container = styled.div`
  padding: 0;
  width: 100%;
  position: relative;
  overflow-x: hidden;

  div.top {
    height: 240px;
    width: 100%;
    position: relative;
    padding: 10rem 5%;
    padding-bottom: 5rem;
    background: url("/images/contactusBlurBg.svg");
    background-size: 100%;

    h1.contact-title {
      display: block;
      z-index: 99;
      font-weight: 600;
      font-size: 7.6rem;
      line-height: 9.6rem;
      letter-spacing: -0.06em;
      color: #1c344f;
    }
  }

  form {
    margin: 5rem 0;
    padding-left: 5%;
  }

  @media screen and (max-width: ${DESKTOP_MIN_WIDTH}) {
    div.top {
      height: 200px;
      padding: 2rem;

      h1.contact-title {
        font-size: 3.2rem;
        line-height: 3.9rem;
      }
    }

    form {
      margin: 0;
      padding: 0 2rem;
    }
  }

  @media screen and (min-width: ${LARGE_SCREEN_MIN_WIDTH}) {
    div.top {
      padding: 10rem 15%;

      h1.contact-title {
        font-size: 9.6rem;
        line-height: 11.6rem;
      }
    }

    form {
      padding-left: 15%;
    }
  }
`;

const easeing = [0.6, -0.05, 0.01, 0.99];

const ContactUsPage = () => {
  return (
    <Container>
      <div className="top">
        <motion.h1 
          className="contact-title"
          initial={{ x: -200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          Contact Us
        </motion.h1>
      </div>

      <AnimateOnScroll variants={VARIANTS.slideInUp}>
        <ContactForm />
      </AnimateOnScroll>
    </Container>
  );
};

export default ContactUsPage;
