import styled from "styled-components";
import { Benefit as BenefitData } from "../pages/home.page";
import { Benefit } from "../shared/style";

const Container = styled.div`
  width: 90%;
`;

interface BenefitsNavProps {
  activeBenefit: number;
  setActiveBenefit: React.Dispatch<React.SetStateAction<number>>;
  benefits: BenefitData[];
}

const BenefitsNav: React.FC<BenefitsNavProps> = ({
  activeBenefit,
  benefits,
  setActiveBenefit,
}) => {
  const handleClick = (idx: number) => () => {
    setActiveBenefit(idx);
  };

  return (
    <Container>
      {benefits.map((benefit, index) => {
        return (
          <Benefit
            key={`benefit-${index}-${benefit.title}`}
            active={activeBenefit === index}
            onClick={handleClick(index)}
          >
            <h1 className="benefit-title">{benefit.title}</h1>

            <span className="benefit-text">{benefit.text}</span>
          </Benefit>
        );
      })}
    </Container>
  );
};

export default BenefitsNav;
