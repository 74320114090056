import axios from "axios";
import { FormEvent, useState } from "react";
import styled from "styled-components";
import { API_BASE_URL, DESKTOP_MIN_WIDTH, VARIANTS } from "../../utils/constants";
import CustomButton from "../cutom-button.component";
import { Overlay } from "../overlay.component";
import { ContactSubmitionSuccess } from "../overlays/contactus-success.overlay";
import TextAreaComponent from "../textarea.component";
import InputComponent from "../textfield.component";
import { Toast, ToastStatus } from "../toast.component";
import Topic from "../topic.component";
import AnimateOnScroll from "../AnimateOnScroll";

const Container = styled.form`
  width: 100%;
  max-width: 70rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  label {
    display: block;

    span {
      display: block;
      font-size: 1.8rem;
      line-height: 2.2rem;
      color: #1c344f;
      margin-bottom: 1rem;
    }

    .input-wrapper {
      border: 1px solid #dce2e6;
      background: none;

      input {
        background: none;
      }
    }

    div.topics {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2rem;
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: ${DESKTOP_MIN_WIDTH}) {
    button {
      width: 50%;
      margin-bottom: 2rem;
    }
  }
`;

const topics = [
  "donation",
  "pickup",
  "recyclable product",
  "notifications",
  "others",
];

export interface ContactFormProps {}

const ContactForm: React.FC<ContactFormProps> = () => {
  const [selectedTopic, setSelectedTopic] = useState<string>("donation");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [isSuccessOverlayOpen, setIsSuccessOverlayOpen] =
    useState<boolean>(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await axios.post(`${API_BASE_URL}/public/contact-request`, {
        topic: selectedTopic,
        name,
        email,
        description,
      });

      if (res.status === 200) {
        setSelectedTopic("donation");
        setEmail("");
        setName("");
        setDescription("");

        setIsSuccessOverlayOpen(true);
      }
    } catch (e) {
      setError("Failed to submit request. Try again");

      setTimeout(() => {
        setError(null);
      }, 4000);
    }

    setLoading(false);
  };

  return (
    <Container onSubmit={handleSubmit}>
      {error && (
        <Toast
          text={error}
          timeout={3000}
          toastStatus={ToastStatus.failed}
          width="400px"
        />
      )}

      <Overlay
        closeOverlay={() => setIsSuccessOverlayOpen(false)}
        isVisible={isSuccessOverlayOpen}
        Component={ContactSubmitionSuccess}
        extraProps={{}}
      />

      <label htmlFor="name">
        <span>Name</span>
        <InputComponent
          id="name"
          type="text"
          required
          value={name}
          onChange={setName}
        />
      </label>

      <label htmlFor="email">
        <span>Email</span>
        <InputComponent
          id="email"
          type="email"
          required
          value={email}
          onChange={setEmail}
        />
      </label>

      <label>
        <span>Topic</span>

        <div className="topics">
          {topics.map((topic, idx) => {
            return (
              <Topic
                key={"topic-" + idx}
                text={topic}
                selected={topic === selectedTopic}
                onClick={() => setSelectedTopic(topic)}
              />
            );
          })}
        </div>
      </label>

      <label htmlFor="email">
        <span>Description</span>
        <TextAreaComponent
          id="description"
          required
          value={description}
          onChange={setDescription}
        />
      </label>

      <AnimateOnScroll variants={VARIANTS.slideInRight}>
        <CustomButton type="submit" title="SUBMIT" disabled={loading} />
      </AnimateOnScroll>
    </Container>
  );
};

export default ContactForm;
