import styled from "styled-components";
import PrivacyPolicyToc from "../components/privacy-toc.component";
import { COLORS } from "../utils/colors";
import { DESKTOP_MIN_WIDTH, VARIANTS } from "../utils/constants";
import { motion } from "framer-motion";
import AnimateOnScroll from "../components/AnimateOnScroll";
import SectionInViewMonitor from "../components/SectionInViewMonitor";
import { useMemo, useState } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${COLORS.grey_main};
    padding: 4rem 0;

    h1 {
      display: block;
      font-style: normal;
      font-weight: 600;
      font-size: 8.6rem;
      line-height: 10.6rem;
      letter-spacing: -0.04em;
    }

    span {
      display: block;
      font-weight: 400;
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  div.content {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    position: sticky;
    top: 30px;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      line-height: 3.2rem;
      text-align: justify;
      color: ${COLORS.grey_600};
    }

    div.left {
      padding-left: 5rem;
      width: 330px;
      padding-right: 1rem;
      box-sizing: content-box;
      position: sticky;
      top: 90px;
      height: calc(100vh - 90px);
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      ul {
        list-style: none;
        
        li {
          display: block;
          margin-bottom: 2rem;
        }

        li > a {    
          display: inline-block;        
          font-style: normal;
          font-weight: 500;
          font-size: 2.4rem;
          line-height: 2.9rem;
          text-decoration: none;
          letter-spacing: -0.04em;
          color: ${COLORS.grey_400};
          
          cursor: pointer;
          transition: 0.3s;
          text-transform: capitalize;

          &.selected {
            color: ${COLORS.primary};
            margin-left: 1rem;
          }

          &:hover {
            color: ${COLORS.primary};
            margin-left: 1.6rem;
          }
        }

      }

    }

    div.right {
      flex: 1;
      padding-right: 5rem;

      section {
        margin: 4rem 0;

        h3.title {
          display: block;
          font-weight: 600;
          font-size: 2rem;
          line-height: 3.2rem;
          text-align: justify;
          color: color: ${COLORS.grey_main};
          margin-bottom: 2rem;
          text-transform: uppercase;
          scroll-margin-top: 90px;
        }
      }
    } 
  }

  @media screen and (max-width: ${DESKTOP_MIN_WIDTH}) {
    height: auto;
    overflow: hidden;

    header {
      padding: 2rem 0;

      h1 {
        font-size: 5.6rem;
        text-align: center;
      }
    }

    div.content {
      flex-direction: column;
      align-items: center;
      position: relative;

      div.left {
        display: none;
      }

      div.right {
        width: 100%;
        flex: none;
        padding: 0 2rem;
      }
    }
  }
`;

const TermsPage = () => {
  const [visibleTitles, setVisibleTitles] = useState<boolean[]>(new Array<boolean>(17).fill(false));

  const activeTitle = useMemo(() => {
    for (let i = 0; i < visibleTitles.length; i++) {
      if (visibleTitles[i]) {
        return i + 1;
      };
    }

    return null;
  }, [visibleTitles]);

  const onTitleVisible = (idx: number, inView: boolean) => {
    setVisibleTitles((prev) => {
      const d = [...prev];

      d[idx - 1] = inView;

      return d;
    })
  }

  return (
    <Container>
      <header>
        <motion.h1
          initial={{ x: -200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          Terms of Service
        </motion.h1>

        <motion.span
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.7 }}
        >
          Updated July 29, 2022
        </motion.span>
      </header>

      <AnimateOnScroll variants={VARIANTS.slideInUp}>
        <div className="content">
          <div className="left">
            <PrivacyPolicyToc activeTitle={activeTitle} />
          </div>

          <div className="right">
            <p>
              Recyclo is licensed to You for use only under the terms of this
              License Agreement. By downloading the Application from the Apple
              AppStore/ Google Play Store, and any update thereto (as permitted by
              this License Agreement), You indicate that You agree to be bound by
              all of the terms and conditions of this License Agreement, and that
              You accept this License Agreement.
              <br /> <br />
              The parties of this License Agreement acknowledge that Apple is not
              a Party to this License Agreement and is not bound by any provisions
              or obligations with regard to the Application, such as warranty,
              liability, maintenance and support thereof. Recyclo, not Apple, is
              solely responsible for the licensed Application and the content
              thereof. This License Agreement may not provide for usage rules for
              the Application that are in conflict with the latest App Store Terms
              of Service. Recyclo acknowledges that it had the opportunity to
              review said terms and this License Agreement is not conflicting with
              them. All rights not expressly granted to You are reserved.
            </p>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(1, inView)}>
              <h3 
                id="title-1" 
                className="title"  
              >
                1. The Application
              </h3>

              <p>
                Recyclo (hereinafter: Application) is a piece of software created
                by Recyclo – and customized for ios/android mobile devices.{" "}
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(2, inView)}>
              <h3 id="title-2" className="title">
                2. Scope of License
              </h3>

              <p>
                2.1 You are given a non-transferable, non-exclusive,
                non-sublicensable license to install and use the Licensed
                Application on any Apple-branded/ Android Products that You
                (End-User) own or control and as permitted by the Usage Rules set
                forth in this section and the App Store Terms of Service, with the
                exception that such licensed Application may be accessed and used
                by other accounts associated with You (End-User, The Purchaser)
                via Family Sharing or volume purchasing.
                <br />
                <br />
                2.2 This license will also govern any updates of the Application
                provided by Licensor that replace, repair, and/or supplement the
                first Application, unless a separate license is provided for such
                update in which case the terms of that new license will govern.
                <br />
                <br />
                2.3 You may not share or make the Application available to third
                parties (unless to the degree allowed by the Apple Terms and
                Conditions, and with Recyclo’s prior written consent), sell, rent,
                lend, lease or otherwise redistribute the Application.
                <br />
                <br />
                2.4 You may not reverse engineer, translate, disassemble,
                integrate, decompile, integrate, remove, modify, combine, create
                derivative works or updates of, adapt, or attempt to derive the
                source code of the Application, or any part thereof (except with
                Recyclo’s prior written consent).
                <br />
                <br />
                2.5 You may not copy (excluding when expressly authorized by this
                license and the Usage Rules) or alter the Application or portions
                thereof. You may create and store copies only on devices that You
                own or control for backup keeping under the terms of this license,
                the App Store/Play Store Terms of Service, and any other terms and
                conditions that apply to the device or software used. You may not
                remove any intellectual property notices. You acknowledge that no
                unauthorized third parties may gain access to these copies at any
                time.
                <br />
                <br />
                2.6 Violations of the obligations mentioned above, as well as the
                attempt of such infringement, may be subject to prosecution and
                damages.
                <br />
                <br />
                2.7 Licensor reserves the right to modify the terms and conditions
                of licensing.
                <br />
                <br />
                2.8 Nothing in this license should be interpreted to restrict
                third-party terms. When using the Application, You must ensure
                that You comply with applicable third-party terms and conditions.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(3, inView)}>
              <h3 id="title-3" className="title">
                3. Technical Requirements
              </h3>

              <p>
                3.1 Licensor attempts to keep the Application updated so that it
                complies with modified/new versions of the firmware and new
                hardware. You are not granted rights to claim such an update.
                <br />
                <br />
                3.2 You acknowledge that it is Your responsibility to confirm and
                determine that the app end-user device on which You intend to use
                the Application satisfies the technical specifications mentioned
                above.
                <br />
                <br />
                3.3 Licensor reserves the right to modify the technical
                specifications as it sees appropriate at any time.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(4, inView)}>
              <h3 
                id="title-4" 
                className="title" 
              >
                4. Maintenance and Support 
              </h3>

              <p>
                4.1 The Licensor is solely responsible for providing any
                maintenance and support services for this licensed Application.
                You can reach the Licensor at the email address listed in the App
                Store/Play Store Overview for this licensed Application.
                <br />
                <br />
                4.2 and the End-User acknowledges that Apple has no obligation
                whatsoever to furnish any maintenance and support services with
                respect to the licensed Application.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(5, inView)}>
              <h3 
                id="title-5" 
                className="title"  
              >
                5. Use of Data
              </h3>

              <p>
                You acknowledge that Licensor will be able to access and adjust
                Your downloaded licensed Application content and Your personal
                information, and that Licensor’s use of such material and
                information is subject to Your legal agreements with Licensor and
                Licensor’s privacy policy: Template awaiting more details .
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(6, inView)}>
              <h3 
                id="title-6" 
                className="title" 
              >
                6. User Generated Contributions
              </h3>

              <p>
                The Application may invite you to chat, contribute to, or
                participate in blogs, message boards, online forums, and other
                functionality, and may provide you with the opportunity to create,
                submit, post, display, transmit, perform, publish, distribute, or
                broadcast content and materials to us or in the Application,
                including but not limited to text, writings, video, audio,
                photographs, graphics, comments, suggestions, or personal
                information or other material (collectively, “Contributions”).
                Contributions may be viewable by other users of the Application
                and through third-party websites or applications. As such, any
                Contributions you transmit may be treated as non-confidential and
                non-proprietary. When you create or make available any
                Contributions, you thereby represent and warrant that: 6.1 The
                creation, distribution, transmission, public display, or
                performance, and the accessing, downloading, or copying of your
                Contributions do not and will not infringe the proprietary rights,
                including but not limited to the copyright, patent, trademark,
                trade secret, or moral rights of any third party.
                <br />
                <br />
                6.2 You are the creator and owner of or have the necessary
                licenses, rights, consents, releases, and permissions to use and
                to authorize us, the Application, and other users of the
                Application to use your Contributions in any manner contemplated
                by the Application and these Terms of Use.
                <br />
                <br />
                6.3 You have the written consent, release, and/or permission of
                each and every identifiable individual person in your
                Contributions to use the name or likeness or each and every such
                identifiable individual person to enable inclusion and use of your
                Contributions in any manner contemplated by the Application and
                these Terms of Use.
                <br />
                <br />
                6.4 Your Contributions are not false, inaccurate, or misleading.
                <br />
                <br />
                6.5 Your Contributions are not unsolicited or unauthorized
                advertising, promotional materials, pyramid schemes, chain
                letters, spam, mass mailings, or other forms of solicitation.
                <br />
                <br />
                6.6 Your Contributions are not obscene, lewd, lascivious, filthy,
                violent, harassing, libelous, slanderous, or otherwise
                objectionable (as determined by us).
                <br />
                <br />
                6.7 Your Contributions do not ridicule, mock, disparage,
                intimidate, or abuse anyone.
                <br />
                <br />
                6.8 Your Contributions are not used to harass or threaten (in the
                legal sense of those terms) any other person and to promote
                violence against a specific person or class of people.
                <br />
                <br />
                6.9 Your Contributions do not violate any applicable law,
                regulation, or rule.
                <br />
                <br />
                6.91 Your Contributions do not violate the privacy or publicity
                rights of any third party.
                <br />
                <br />
                6.92 Your Contributions do not violate any applicable law
                concerning child pornography, or otherwise intended to protect the
                health or well-being of minors.
                <br />
                <br />
                6.93 Your Contributions do not include any offensive comments that
                are connected to race, national origin, gender, sexual preference,
                or physical handicap.
                <br />
                <br />
                6.94 Your Contributions do not otherwise violate, or link to
                material that violates, any provision of these Terms of Use, or
                any applicable law or regulation.
                <br />
                <br />
                6.95 Any use of the Application in violation of the foregoing
                violates these Terms of Use and may result in, among other things,
                termination or suspension of your rights to use the Application.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(7, inView)}>
              <h3 
                id="title-7" 
                className="title" 
              >
                7. Contribution License
              </h3>

              <p>
                By posting your Contributions to any part of the Application or
                making Contributions accessible to the Application by linking your
                account from the Application to any of your social networking
                accounts, you automatically grant, and you represent and warrant
                that you have the right to grant, to us an unrestricted,
                unlimited, irrevocable, perpetual, non-exclusive, transferable,
                royalty-free, fully-paid, worldwide right, and license to host,
                use copy, reproduce, disclose, sell, resell, publish, broad cast,
                retitle, archive, store, cache, publicly display, reformat,
                translate, transmit, excerpt (in whole or in part), and distribute
                such Contributions (including, without limitation, your image and
                voice) for any purpose, commercial advertising, or otherwise, and
                to prepare derivative works of, or incorporate in other works,
                such as Contributions, and grant and authorize sublicenses of the
                foregoing. The use and distribution may occur in any media formats
                and through any media channels.
                <br />
                <br />
                This license will apply to any form, media, or technology now
                known or hereafter developed, and includes our use of your name,
                company name, and franchise name, as applicable, and any of the
                trademarks, service marks, trade names, logos, and personal and
                commercial images you provide. You waive all moral rights in your
                Contributions, and you warrant that moral rights have not
                otherwise been asserted in your Contributions.
                <br />
                <br />
                We do not assert any ownership over your Contributions. You retain
                full ownership of all of your Contributions and any intellectual
                property rights or other proprietary rights associated with your
                Contributions. We are not liable for any statements or
                representations in your Contributions provided by you in any area
                in the Application. You are solely responsible for your
                Contributions to the Application and you expressly agree to
                exonerate us from any and all responsibility and to refrain from
                any legal action against us regarding your Contributions.
                <br />
                <br />
                We have the right, in our sole and absolute discretion, (1) to
                edit, redact, or otherwise change any Contributions; (2) to
                re-categorize any Contributions to place them in more appropriate
                locations in the Application; and (3) to pre-screen or delete any
                Contributions at any time and for any reason, without notice. We
                have no obligation to monitor your Contributions.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(8, inView)}>
              <h3 
                id="title-8" 
                className="title" 
              >
                8. Liability
              </h3>

              <p>
                8.1 Licensor’s responsibility in the case of violation of
                obligations and tort shall be limited to intent and gross
                negligence. Only in case of a breach of essential contractual
                duties (cardinal obligations), Licensor shall also be liable in
                case of slight negligence. In any case, liability shall be limited
                to the foreseeable, contractually typical damages. The limitation
                mentioned above does not apply to injuries to life, limb, or
                health.
                <br />
                <br />
                8.2 Licensor takes no accountability or responsibility for any
                damages caused due to a breach of duties according to Section 2 of
                this Agreement. To avoid data loss, You are required to make use
                of backup functions of the Application to the extent allowed by
                applicable third-party terms and conditions of use. You are aware
                that in case of alterations or manipulations of the Application,
                You will not have access to licensed Application.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(9, inView)}>
              <h3 
                id="title-9" 
                className="title" 
              >
                9. Warranty
              </h3>

              <p>
                9.1 Licensor warrants that the Application is free of spyware,
                trojan horses, viruses, or any other malware at the time of Your
                download. Licensor warrants that the Application works as
                described in the user documentation.
                <br /> <br />
                9.2 No warranty is provided for the Application that is not
                executable on the device, that has been unauthorizedly modified,
                handled inappropriately or culpably, combined or installed with
                inappropriate hardware or software, used with inappropriate
                accessories, regardless if by Yourself or by third parties, or if
                there are any other reasons outside of Recyclo’s sphere of
                influence that affect the executability of the Application.
                <br />
                <br />
                9.3 You are required to inspect the Application immediately after
                installing it and notify Recyclo about issues discovered without
                delay by email provided in Product Claims. The defect report will
                be taken into consideration and further investigated if it has
                been mailed within a period of thirty one (31) days after
                discovery.
                <br />
                <br />
                9.4 reserves a choice to remedy the situation either by means of
                solving the defect or substitute delivery.
                <br />
                <br />
                9.5 In the event of any failure of the Application to conform to
                any applicable warranty, You may notify the App-Store-Operator,
                and Your Application purchase price will be refunded to You. To
                the maximum extent permitted by applicable law, the
                App-Store-Operator will have no other warranty obligation
                whatsoever with respect to the App, and any other losses, claims,
                damages, liabilities, expenses and costs attributable to any
                negligence to adhere to any warranty.
                <br />
                <br />
                9.6 If the user is an entrepreneur, any claim based on faults
                expires after a statutory period of limitation amounting to twelve
                (12) months after the Application was made available to the user.
                The statutory periods of limitation given by law apply for users
                who are consumers.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(10, inView)}>
              <h3 
                id="title-10" 
                className="title"
              >
                10. Product Claims
              </h3>

              <p>
                Recyclo and the End-User acknowledge that Recyclo, and not
                Apple/Google, is responsible for addressing any claims of the
                End-User or any third party relating to the licensed Application
                or the End-User’s possession and/or use of that licensed
                Application, including, but not limited to: i). Product liability
                claims;
                <br />
                <br />
                ii). Any claim that the licensed Application fails to conform to
                any applicable legal or regulatory requirement; and
                <br />
                <br />
                iii). Claims arising under consumer protection, privacy, or
                similar legislation, including in connection with Your Licensed
                Application’s use of the HealthKit and HomeKit.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(11, inView)}>
              <h3 
                id="title-11" 
                className="title"
              >
                11. Legal Compliance
              </h3>

              <p>
                You represent and warrant that You are not located in a country
                that is subject to a U.S. Government embargo, or that has been
                designated by the U.S. Government as a “terrorist supporting”
                country; and that You are not listed on any U.S. Government list
                of prohibited or restricted parties.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(12, inView)}>
              <h3 
                id="title-12" 
                className="title" 
              >
                12. Contact Information
              </h3>

              <p>
                For general inquiries, complaints, questions or claims concerning
                the licensed Application, please contact: Recyclo
                <br />
                <b>Business address:</b> 65 Gilbert Street, Flat 39, United
                Kingdom
                <br />
                <b>Email address:</b> support@recyclo.org.uk
                <br />
                <b>Telephone:</b> +447883551139
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(13, inView)}>
              <h3 
                id="title-13" 
                className="title" 
              >
                13. Termination
              </h3>

              <p>
                The license is valid until terminated by Recyclo or by You. Your
                rights under this license will terminate automatically and without
                notice from Recyclo if You fail to adhere to any term(s) of this
                license. Upon License termination, You shall stop all use of the
                Application, and destroy all copies, full or partial, of the
                Application.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(14, inView)}>
              <h3 
                id="title-14" 
                className="title"
              >
                14. Third-party terms of agreement and beneficiary
              </h3>

              <p>
                Recyclo represents and warrants that Recyclo will comply with
                applicable third-party terms of agreement when using licensed
                Application.
                <br />
                <br />
                In Accordance with Section 9 of the “Instructions for Minimum
                Terms of Developer’s End-User License Agreement,” Apple and
                Apple’s subsidiaries shall be third-party beneficiaries of this
                End User License Agreement and – upon Your acceptance of the terms
                and conditions of this license agreement, Apple will have the
                right (and will be deemed to have accepted the right) to enforce
                this End User License Agreement against You as a third-party
                beneficiary thereof.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(15, inView)}>
              <h3 
                id="title-15" 
                className="title"
              >
                15. Intellectual property rights
              </h3>

              <p>
                Recyclo and the End-User acknowledge that, in the event of any
                third-party claim that the licensed Application or the End-User’s
                possession and use of that licensed Application infringes on the
                third party’s intellectual property rights, Recyclo, and not
                Apple/Google, will be solely responsible for the investigation,
                defense, settlement and discharge or any such intellectual
                property infringement claims.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(16, inView)}>
              <h3 
                id="title-16" 
                className="title"
              >
                16. Applicable Law
              </h3>

              <p>
                This license agreement is governed by the laws of the United
                Kingdom excluding its conflicts of law rules.
              </p>
            </SectionInViewMonitor>

            <SectionInViewMonitor toggleVisible={(inView) => onTitleVisible(17, inView)}>
              <h3 
                id="title-17" 
                className="title"
              >
                17. Miscellaneous
              </h3>

              <p>
                17.1 If any of the terms of this agreement should be or become
                invalid, the validity of the remaining provisions shall not be
                affected. Invalid terms will be replaced by valid ones formulated
                in a way that will achieve the primary purpose.
                <br />
                <br />
                17.2 Collateral agreements, changes and amendments are only valid
                if laid down in writing. The preceding clause can only be waived
                in writing.
              </p>
            </SectionInViewMonitor>
          </div>
        </div>
      </AnimateOnScroll>
    </Container>
  );
};

export default TermsPage;
