import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

interface Props {
    children: JSX.Element[];
    toggleVisible: (val: boolean) => void;
}

const SectionInViewMonitor: React.FC<Props> = ({ children, toggleVisible }) => {
    const [ref, inView] = useInView();

    useEffect(() => {
        toggleVisible(inView);
    }, [inView]);

    return <section ref={ref}>{children}</section>;
}

export default SectionInViewMonitor;