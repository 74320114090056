import { Variants, motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

interface Props {
    children: JSX.Element;
    variants: Variants;
    animateOnlyOnce?: boolean;
}

const AnimateOnScroll: React.FC<Props> = ({ children, variants, animateOnlyOnce = true }) => {
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            if (!animateOnlyOnce)
                control.start("hidden");
        }
    }, [control, inView, animateOnlyOnce]);

    return (
        <motion.div
            ref={ref}
            variants={variants}
            initial="hidden"
            animate={control}
        >
            {children}
        </motion.div>
    )
}

export default AnimateOnScroll;