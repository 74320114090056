import React from "react";

const getNestedHeadings = (headingElements: HTMLHeadingElement[]) => {
  const nestedHeadings: { id: string; title: string }[] = [];

  headingElements.forEach((heading, index) => {
    const { innerText: title, id } = heading;

    // if (heading.nodeName === "H2") {
    nestedHeadings.push({ id, title });
    // } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
    //   nestedHeadings[nestedHeadings.length - 1].items.push({
    //     id,
    //     title,
    //   });
    // }
  });

  return nestedHeadings;
};

/**
 * Dynamically generates the table of contents list, using any H2s and H3s it can find in the main text
 */
export const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = React.useState<
    { id: string; title: string }[]
  >([]);

  React.useEffect(() => {
    const headingElements = Array.from(
      document.querySelectorAll("section h3")
    ) as HTMLHeadingElement[];

    // Created a list of headings, with H3s nested
    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, []);

  return { nestedHeadings };
};
